import { UserMessagesService } from 'src/app/services/user-messages/user-messages.service';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { depositTrx, gamesCategoryTrx, inboxTrx, liveCasinoTrx, paymentTrx, searchGameTrx, signUpTrx } from 'src/app/router-translation.labels';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { isPlatformServer } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserMesasgeEntry } from 'src/app/services/user-messages/user-message.models';

@Component({
  selector: 'app-bottom-toolbar',
  templateUrl: './bottom-toolbar.component.html',
  styleUrls: ['./bottom-toolbar.component.scss']
})
export class BottomToolbarComponent extends BasePageComponent {

  isServer: boolean;

  paymentUrl: string = this.translationConfig.getTranslation(paymentTrx) + '/' + this.translationConfig.getTranslation(depositTrx);
  searchUrl: string = this.translationConfig.getTranslation(searchGameTrx);
  singnUrl: string = this.translationConfig.getTranslation(signUpTrx);
  inboxUrl: string = this.translationConfig.getTranslation(inboxTrx);
  liveCasinoUrl: string = this.translationConfig.getTranslation(liveCasinoTrx);
  gameCategoryUrl: string = this.translationConfig.getTranslation(gamesCategoryTrx);
  isLogged = false;
  isHidden = false;
  unreadMessages = 0;
  timerSubscription$: Subscription;
  messagesToShow: UserMesasgeEntry[];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    protected translationConfig: TranslationConfig,
    private loginStatusService: LoginStatusService,
    protected licenceService: LicenceService,
    protected minimizeService: MinimizeSiteService,
    private userMessagesService: UserMessagesService,
    @Inject(PLATFORM_ID) private platformId
  ) { super(); }


  ngOnInit(): void {
    this.isServer = isPlatformServer(this.platformId);
    this.loginStatusService.getIfUserLogged().pipe(take(1)).subscribe(() => {
      this.isLogged = true;
    });
    this.minimizeService.isToolbarNotMinimized().pipe(takeUntil(this.unsubscribe)).subscribe((isNotMinimized) => {
      this.isHidden = !isNotMinimized;
      if (isNotMinimized) {
        this.startCheckingForUnreadMessages();
      }
    });

    this.userMessagesService.unreadMessagesCount.pipe(takeUntil(this.unsubscribe)).subscribe((unreadCount) => {
      this.unreadMessages = unreadCount;
    });
  }

  public startCheckingForUnreadMessages(interval: number = environment.checkInboxInterval) {
    this.loginStatusService.getLoginPartialStatus().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.isLogged = resp;
      if (this.isLogged) {
        this.timerSubscription$ = timer(0, interval).pipe(
          switchMap(() => this.userMessagesService.requestUserMessagesInfo()),
          takeUntil(this.unsubscribe)
        ).subscribe((messagesToShow) => {
          if (!!messagesToShow && messagesToShow.length > 0) this.router.navigate(['', inboxTrx, 'open', messagesToShow.pop().id])
        });
      }
    });
  }

  catagoryRedirect() {
    this.router.navigate([this.gameCategoryUrl]);
  }
}
