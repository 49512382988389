import { Apollo } from 'apollo-angular';
import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';
import { FRONT_PAGE_GAME_DESCRIPTION } from 'src/app/apollo/front-page-games/front-page-games';
import { GameItem, GameResponse } from 'src/app/apollo/models/base-models';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { gameInfoTrx, launchGameTrx } from 'src/app/router-translation.labels';
import { FULL_DIALOG_CONFIG } from '../dialog.config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { ClonerService } from 'src/app/services/utils/clone-object.service';

const resumeGameMsg: string = $localize`:@@resumeGameMsg:You will be automatically redirected to you last played game session in SECONDS_PLACEHOLDER seconds...`;
const secondsPlaceholder = 'SECONDS_PLACEHOLDER';
const autoconfirmTimeInSec = 5;

export interface DialogData {
  gameName: string;
  gameId: string;
  thumbnail: string;
  url: string;
  id: string;
  background: string
}

@Component({
  template: ''
})
export class ResumeGameDialogRouteComponent extends BasePageComponent {

  constructor(public dialog: MatDialog,
    private router: Router,
    private apollo: Apollo,
    private loaderService: LoaderService,
    private returnUrlService: DialogClosePreviousUrl,
    private cloneService: ClonerService,
    @Inject(LOCALE_ID) public locale: string,
    private route: ActivatedRoute) { super() }

  ngOnInit(): void {
    this.loaderService.show();
    this.route.paramMap.pipe(
      take(1),
      switchMap((params: ParamMap) => {
        const gameName = params.get('game').split('_').join(' ');
        return this.apollo
          .watchQuery<GameResponse>({
            query: FRONT_PAGE_GAME_DESCRIPTION,
            variables: {
              gameName: gameName,
              locale: this.locale
            }
          })
          .valueChanges
      }),
      take(1),
      takeUntil(this.unsubscribe)
    ).subscribe((response) => {
      if (!response.data?.game) {
        this.openDialog(null);
      }
      else {
        const pageJson: GameItem = this.cloneService.deepClone(response.data.game);
        this.openDialog(pageJson);
      }
      this.loaderService.hide();

    }, (error) => {
      this.openDialog(null);
    });
  }

  openDialog(game: GameItem): void {
    const dialogRef = this.dialog.open(ResumeGameDialogComponent, {
      ...FULL_DIALOG_CONFIG,
      panelClass: "resume-game-dialog",
      data: game
    });

    dialogRef.afterClosed().pipe(
      switchMap(() => {
        return this.returnUrlService.getUrl()
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(url => {
      if (url) this.router.navigate(["", ...url]);
      else this.router.navigate([""]);
    });
  }
}


@Component({
  selector: 'app-resume-game-dialog',
  templateUrl: './resume-game-dialog.component.html',
  styleUrls: ['./resume-game-dialog.component.scss']
})
export class ResumeGameDialogComponent extends BaseComponent implements OnDestroy {

  @ViewChild('resumegamedialogwrapper') resumeGameDialogWrapper: ElementRef;
  public secondsLeft: number = autoconfirmTimeInSec;
  public resumeGameMsg: string;
  private intervalId: any;
  constructor(
    public dialogRef: MatDialogRef<ResumeGameDialogComponent>,
    private router: Router,
    protected translationConfig: TranslationConfig,
    public minimizeSiteService: MinimizeSiteService,
    @Inject(MAT_DIALOG_DATA) public data: GameItem) { super() }

  closeDialog(): void {
    clearInterval(this.intervalId);
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.resumeGameMsg = resumeGameMsg.replace(secondsPlaceholder, this.secondsLeft.toString());
    this.intervalId = setInterval(() => {
      this.secondsLeft--;
      if (this.secondsLeft <= 0) {
        clearInterval(this.intervalId);
        this.launchGame();
      }
      this.resumeGameMsg = resumeGameMsg.replace(secondsPlaceholder, this.secondsLeft.toString());
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  launchGame() {
    this.router.navigate([this.translationConfig.getTranslation(launchGameTrx), this.data.gameid], {
      queryParams: { gameName: this.data.systemName },
      skipLocationChange: true
    }).then(() => {
      this.closeDialog();
      window.history.replaceState(null, null, this.translationConfig.getTranslation(gameInfoTrx) + '/' + this.data.systemName);
    });
  }
}
