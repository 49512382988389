import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';
import { BalanceInAppInfo } from 'src/app/services/wallet/wallet.models';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent extends BasePageComponent {

  public redirectUrl: string = null;
  public balanceInfo: BalanceInAppInfo;

  constructor(
    public dialogRef: MatDialogRef<PaymentConfirmationComponent>,
    private balanceStatusInfoService: BalanceStatusService,
    @Inject(MAT_DIALOG_DATA) public successData: { msg: string, title?: string}
  ) {
    super();
  }

  ngOnInit(): void {
    this.balanceStatusInfoService.getBalanceInfoStatus().pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      this.balanceInfo = resp;
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
