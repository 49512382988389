<div class="footer">
    <div class="middle-footer" fxLayout="column" fxLayoutAlign="center center">
        <div class="fixed-width">
            <div class="footer-link-grid" fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="space-between center">
                <mat-form-field *ngIf="selectedLanguage" appearance="outline" class="select-language">
                    <mat-select panelClass="language-overlay d-language-overlay" [value]="selectedLanguage?.name" (selectionChange)="setCookie($event)">
                        <mat-select-trigger class="selected-container">
                            <img [src]="selectedLanguage?.flag?.url">
                            <span>{{selectedLanguage.fullName}}</span>
                            <mat-icon matSuffix>arrow_forward_ios</mat-icon>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of childData?.languages" [value]="item?.name">
                            <a href="/{{item?.name}}/">
                                <img class="select-img" [src]="item?.flag.url">
                                <span>{{item.fullName}}</span>
                            </a>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngFor="let item of childData?.footer?.footerLinks">
                    <div><a routerLink="/{{ item.category }}/{{ item.name }}">{{ item.displayName }}</a></div>
                </div>
                <div class="exteranl-links" *ngFor="let item of childData?.footer?.footerExternalLinks">
                    <div><a target="_blank" rel="noreferrer" href="{{item.url}}">{{ item.name }}</a></div>
                </div>
            </div>
            <div class="payment-options logo-wrap">
                <h5 i18n>Our Payment Options</h5>
                <div class="logo-list" fxLayout="row" fxLayoutAlign="center center">
                    <img src="assets/desktop/payments-icon/icon-payments-applepay.svg" alt="icon-payments-applepay"/>
                    <img src="assets/desktop/payments-icon/icon-payments-astropay.svg" alt="icon-payments-astropay"/>
                    <img src="assets/desktop/payments-icon/icon-payments-blik.svg" alt="icon-payments-blik"/>
                    <img src="assets/desktop/payments-icon/icon-payments-blik-mifinity.svg" alt="icon-payments-blik-mifinity"/>
                    <img src="assets/desktop/payments-icon/icon-payments-cashtocode-mifinity.svg" alt="icon-payments-cashtocode-mifinity"/>
                    <img src="assets/desktop/payments-icon/icon-payments-crypto.svg" alt="icon-payments-crypto"/>
                    <img src="assets/desktop/payments-icon/icon-payments-euteller.svg" alt="icon-payments-euteller"/>
                    <img src="assets/desktop/payments-icon/icon-payments-googlepay.svg" alt="icon-payments-googlepay"/>
                    <img src="assets/desktop/payments-icon/icon-payments-instanteft.svg" alt="icon-payments-instanteft"/>
                    <img src="assets/desktop/payments-icon/icon-payments-interac.svg" alt="icon-payments-interac"/>
                    <img src="assets/desktop/payments-icon/icon-payments-jeton.svg" alt="icon-payments-jeton"/>
                    <img src="assets/desktop/payments-icon/icon-payments-M4.svg" alt="icon-payments-M4"/>
                    <img src="assets/desktop/payments-icon/icon-payments-mastercard.svg" alt="icon-payments-mastercard"/>
                    <img src="assets/desktop/payments-icon/icon-payments-mastercard-2.svg" alt="icon-payments-mastercard-2"/>
                    <img src="assets/desktop/payments-icon/icon-payments-mifinity.svg" alt="icon-payments-mifinity"/>
                    <img src="assets/desktop/payments-icon/icon-payments-mifinity-v2.svg" alt="icon-payments-ifinity-v2"/>
                    <img src="assets/desktop/payments-icon/icon-payments-multibanco.svg" alt="icon-payments-multibanco"/>
                    <img src="assets/desktop/payments-icon/icon-payments-neosurf.svg" alt="icon-payments-neosurf"/>
                    <img src="assets/desktop/payments-icon/icon-payments-neteller.svg" alt="icon-payments-neteller"/>
                    <img src="assets/desktop/payments-icon/icon-payments-paybybank.svg" alt="icon-payments-paybybank"/>
                    <img src="assets/desktop/payments-icon/icon-payments-pay-do.svg" alt="icon-payments-pay-do"/>
                    <img src="assets/desktop/payments-icon/icon-payments-paysafecard.svg" alt="icon-payments-paysafecard"/>
                    <img src="assets/desktop/payments-icon/icon-payments-rapid-transfer.svg" alt="icon-payments-rapid-transfer"/>
                    <img src="assets/desktop/payments-icon/icon-payments-revolut.svg" alt="icon-payments-revolut"/>
                    <img src="assets/desktop/payments-icon/icon-payments-revolut-n26.svg" alt="icon-payments-revolut-n26"/>
                    <img src="assets/desktop/payments-icon/icon-payments-skrill.svg" alt="icon-payments-skrill"/>
                    <img src="assets/desktop/payments-icon/icon-payments-trustly.svg" alt="icon-payments-trustly"/>
                    <img src="assets/desktop/payments-icon/icon-payments-trustly-via-mifinity.svg" alt="icon-payments-trustly-via-mifinity"/>
                    <img src="assets/desktop/payments-icon/icon-payments-visa.svg" alt="icon-payments-visa"/>
                    <img src="assets/desktop/payments-icon/icon-payments-volt.svg" alt="icon-payments-volt"/>
                </div>
            </div>

            <div class="licence-logo" fxLayoutAlign="end center" fxLayout="row" fxLayoutGap="10px">
                <!-- <img *ngFor="let item of childData?.footer?.licenceLogo" alt="{{item?.name}}" src="{{item?.url}}" /> -->
                <!-- <img src="assets/desktop/18+.svg" /> -->
                <img *ngFor="let item of childData?.footer?.licenceLogo" alt="{{item?.name}}" src="{{item?.url}}" />

                <!-- <img src="assets/desktop/licence-logo.svg" /> -->
                <!-- <img src="assets/logo/logo-desktop.svg" /> -->
                <a *ngFor="let item of childData?.footer?.imageWithLinks" target="_blank" rel="nofollow" href="{{item?.url}}"><img alt="{{item?.name}}" src="{{item?.image.url}}" /></a>

            </div>
            <div class="exteranl-links" *ngIf="affiliateUrl">
                <div><a target="_blank" rel="noreferrer" href="{{affiliateUrl}}" i18n>Affiliate Program</a></div>
            </div>
            <div class="licence">{{childData?.footer?.licenceDescription}}</div>
        </div>
    </div>

</div>