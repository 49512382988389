import { Component, EventEmitter, Output } from '@angular/core';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { takeUntil } from 'rxjs/operators';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';

export enum topMenuAction {
  BACK = 1,
  TOP = 2
}

@Component({
  selector: 'app-top-menu-button',
  templateUrl: './top-menu-button.component.html',
  styleUrls: ['./top-menu-button.component.scss']
})
export class TopMenuButtonComponent extends BasePageComponent {

  constructor(
    private toggleBackService: ToggleMenuBackService,
    protected licenceService: LicenceService,
    public navigation: NavigationService,
    private prevUrlService: DialogClosePreviousUrl,
  ) { super() }

  isShowBackButton = false;

  @Output() clicked = new EventEmitter<topMenuAction>();

  ngOnInit(): void {
    this.toggleBackService.attach().pipe(takeUntil(this.unsubscribe)).subscribe((showBack: boolean) => {
      if (showBack) {
        this.isShowBackButton = true;
      }
      else {
        this.isShowBackButton = false;
      }
    })
  }

  openMenu() {
    this.clicked.next(topMenuAction.TOP);
  }

  goBack() {
    console.log("button go back reseting scroll position")
    this.prevUrlService.reset();
    this.clicked.next(topMenuAction.BACK);
  }

}
