import { Component } from '@angular/core';
import { GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from '../../base-page/base-page.component';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { recommendationsTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { RecommendationService } from '../../../services/recommendation/recommendation.service';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})
export class RecommendationsComponent extends BasePageComponent {

  constructor(
    private recommendationService: RecommendationService,
    public detectDesktop: DetectDeviceService,
    private loginStatusService: LoginStatusService,
  ) { super() }

  categoryName: string = $localize`:@@recommendationCategory:recommendations`;
  linkUrl: string = recommendationsTrx;

  games: GameItem[] = [];

  ngOnInit(): void {
    this.loginStatusService.getIfUserLogged().pipe(takeUntil(this.unsubscribe),
      switchMap(() => {
        return this.recommendationService.getRecommendations().pipe(take(1))
      })).subscribe((resp) => {
        if (resp.data?.games && resp.data.games.length > 0) {
          if (this.detectDesktop.isDesktop()) {
            this.games = resp?.data.games.slice(0, 4);
          } else {
            this.games = resp?.data.games.slice(0, 3);
          }
        } else {
          this.games = [];
        }
      });
  }

}
