import { Component } from '@angular/core';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { PrizeBoxService } from 'src/app/services/client-area/prize-box.service';
import { AvatarService } from 'src/app/services/profile/avatar.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { BasePageComponent } from '../../base-page/base-page.component';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent extends BasePageComponent {

  constructor(
    public avatarService: AvatarService,
    public prizeBoxService: PrizeBoxService,
    protected licenceService: LicenceService
  ) { super() }

  avatar: string;
  hasPrizes: boolean = false;


  ngOnInit(): void {
    this.avatarService.getAvatar().pipe(take(1)).subscribe(data => this.avatar = data);
    this.prizeBoxService.getCurrentLevelLottery().pipe(
      switchMap(() => this.prizeBoxService.isActiveLottery()),
      takeUntil(this.unsubscribe)).subscribe(resp => {
        this.hasPrizes = resp;
      })
  }

}
