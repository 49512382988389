import { Component, Inject, LOCALE_ID } from '@angular/core';
import { GameCategory, GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from '../../base-page/base-page.component';
import { takeUntil } from 'rxjs/operators';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LICENCE } from 'src/app/global.tokens';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { RecommendationService } from '../../../services/recommendation/recommendation.service';


@Component({
  selector: 'app-recommendations-full',
  templateUrl: './recommendations-full.component.html',
  styleUrls: ['./recommendations-full.component.scss']
})
export class RecommendationsFullComponent extends BasePageComponent {

  constructor(
    private recommendationService: RecommendationService,
    private toggleBackService: ToggleMenuBackService,
    public detectDeviceService: DetectDeviceService,
    private frontPageGamesService: FrontPageGamesService,
    @Inject(LICENCE) public licence: string,
    @Inject(LOCALE_ID) public locale: string,
    protected licenceService: LicenceService

  ) { super(); }

  games: GameItem[] = [];
  categories: GameCategory[];

  ngOnInit(): void {
    this.toggleBackService.showBack();
    console.log("called full recommendation component")
    this.recommendationService.getRecommendations().pipe(takeUntil(this.unsubscribe)).subscribe(
      resp => {
        this.games = resp?.data?.games ?? [];
      }
    );

    this.frontPageGamesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.categories = response.pagesCategories[0]?.categories;
      // if (isPlatformServer(this.platformId)) {
      //   this.categories = this.categories.slice(0, 1);
      // }
    });
  }
}
