import { Component, OnInit } from '@angular/core';
import { GameCategoryDialogComponent } from '../game-category-dialog/game-category-dialog.component';
import { SwiperOptions } from 'swiper';
import { GameCategory } from 'src/app/apollo/models/base-models';

@Component({
  selector: 'app-game-category-swiper',
  templateUrl: './game-category-swiper.component.html',
  styleUrls: ['./game-category-swiper.component.scss']
})
export class GameCategorySwiperComponent extends GameCategoryDialogComponent {

  config: SwiperOptions = {
    direction: 'horizontal',
    containerModifierClass: 'swiper-slide mobile-home-category-swiper',
    keyboard: true,
    slidesPerView: 4,
    centeredSlides: false,
    loop: false,
    simulateTouch: true,
    roundLengths: true,
    spaceBetween:1,
    breakpoints: {
      '@0.00' : {
        slidesPerView:4.4,
        spaceBetween:1
      },
      '@0.60' : {
        slidesPerView:5.4,
        spaceBetween:1
      },
      '@0.75' : {
        slidesPerView:7.4,
        spaceBetween:1
      },
      '@1.00' : {
        slidesPerView:8.4,
        spaceBetween:1
      },
    }
  };
}
